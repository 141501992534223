/**
 * Service to communicate with operators resource
 */
import {Injectable} from '@angular/core';
import {Operator} from '../model/operator';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorageService} from '../auth/token-storage.service';
import {OperatorSearchCriteria} from './operator-search-criteria.model';
import {OperatorAndShift} from '../model/operatorAndShift';
import {Order} from '../model/order';
import {SimpleOrder} from '../model/simpleOrder';
import {RemovedOrder} from '../model/removedOrder';

@Injectable()
export class OrdersService {
    private static BASE_URL = `${environment.pdOrdersMachineSapIdApiUrl}`;
    private static BASE_URL_REPACKAGING = `${environment.pdOrdersRepackagingApiUrl}`;
    private static BASE_URL_PACKING = `${environment.pdOrdersPackingApiUrl}`;
    private static BASE_URL_ASSEMBLY_AND_PAINT_SHOP = `${environment.pdOrdersAssemblyAndPaintShopApiUrl}`;
    private static BASE_URL_EXTRA_ORDERS = `${environment.pdOrderExtraOrdersApiUrl}`;

    constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
    }

    findAllOrders(operatorSearchCriteria: OperatorSearchCriteria): Observable<Order[]> {
        const params = new HttpParams();
        // let operatorIds = operatorSearchCriteria.operatorId;
        // for (const operatorId of operatorIds) {
        //     params = params.append('roleIds', operatorId.toString());
        // }
        // params = params.append("extendHours", String(operatorSearchCriteria.extendHours));
        const options = {params: params};
        return this.http.get<Order[]>(OrdersService.BASE_URL, options);
    }

    findOrderForMachineSapId(sapId: string) {
        let params = new HttpParams();
        params = params.append('sapId', sapId);
        const options = {params: params};
        return this.http.get<Order[]>(OrdersService.BASE_URL, options);
    }

    findAllOrdersRepackaging() {
        return this.http.get<SimpleOrder[]>(OrdersService.BASE_URL_REPACKAGING);
    }

    findAllOrdersPacking() {
        return this.http.get<SimpleOrder[]>(OrdersService.BASE_URL_PACKING);
    }

    findAllOrdersAssemblyAndPaintShop() {
        return this.http.get<SimpleOrder[]>(OrdersService.BASE_URL_ASSEMBLY_AND_PAINT_SHOP);
    }

    findAllExtraOrders() {
        return this.http.get<SimpleOrder[]>(OrdersService.BASE_URL_EXTRA_ORDERS);
    }
}
