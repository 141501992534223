import {Routes} from '@angular/router';

import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {AuthLayoutComponent} from './layouts/auth/auth-layout.component';
import {AuthGuard} from './auth/auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    }, {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: 'dashboard',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
                canActivate: [AuthGuard]
            },
            {
                path: 'orders',
                loadChildren: './orders/orders.module#OrdersModule',
                canActivate: [AuthGuard]
            }, {
                path: 'machines',
                loadChildren: './machines/machines.module#MachinesModule',
                canActivate: [AuthGuard]
            }, {
                path: '',
                loadChildren: './dashboard/dashboard.module#DashboardModule',
                canActivate: [AuthGuard]
            }, {
                path: 'operators',
                loadChildren: './operators/operators.module#OperatorsModule',
                canActivate: [AuthGuard]
            }, {
                path: 'employees',
                loadChildren: './employees/employees.module#EmployeesModule',
                canActivate: [AuthGuard]
            }, {
                path: 'labels',
                loadChildren: './labels/labels.module#LabelsModule',
                canActivate: [AuthGuard]
            }, {
                path: 'labels_edit/:id',
                loadChildren: './labels/edit/label_edit.module#LabelEditModule',
                canActivate: [AuthGuard]
            }, {
                path: 'machine_setters',
                loadChildren: './machine_setters/machine_setters.module#MachineSettersModule',
                canActivate: [AuthGuard]
            }, {
                path: 'failure_reporting',
                loadChildren: './failure_reporting/failure_reporting.module#FailureReportingModule',
                canActivate: [AuthGuard]
            }, {
                path: 'failure_reporting_finished',
                loadChildren: './failure_reporting/failure_reporting_finished.module#FailureReportingModule',
                canActivate: [AuthGuard]
            }, {
                path: 'failure_reporting_edit/:id',
                loadChildren: './failure_reporting/edit/failure_reporting_edit.module#FailureReportingEditModule',
                canActivate: [AuthGuard]
            }, {
                path: 'shift/:id',
                loadChildren: './shift/shift.module#ShiftModule',
                canActivate: [AuthGuard]
            }, {
                path: 'mobile/:id',
                loadChildren: './mobile/mobile.module#MobileModule',
                canActivate: [AuthGuard]
            }, {
                path: 'report',
                loadChildren: './report/report.module#ReportModule',
                canActivate: [AuthGuard]
            }, {
                path: 'components',
                loadChildren: './components/components.module#ComponentsModule'
            }, {
                path: 'forms',
                loadChildren: './forms/forms.module#Forms'
            }, {
                path: 'tables',
                loadChildren: './tables/tables.module#TablesModule'
            }, {
                path: 'maps',
                loadChildren: './maps/maps.module#MapsModule'
            }, {
                path: 'widgets',
                loadChildren: './widgets/widgets.module#WidgetsModule'
            }, {
                path: 'charts',
                loadChildren: './charts/charts.module#ChartsModule'
            }, {
                path: 'calendar',
                loadChildren: './calendar/calendar.module#CalendarModule'
            }, {
                path: '',
                loadChildren: './userpage/user.module#UserModule'
            }, {
                path: '',
                loadChildren: './timeline/timeline.module#TimelineModule'
            }
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [{
            path: 'pages',
            loadChildren: './pages/pages.module#PagesModule'
        }]
    }
];
