import {MatSnackBar} from '@angular/material/snack-bar';
import {Injectable} from '@angular/core';

/**
 * Messaging service to display user a message
 */
@Injectable()
export class MessageService {

  constructor(private snackBar: MatSnackBar) {
  }

  showMessage(message: string) {
    this.snackBar.open(message, null, {
      duration: 3000
    });
  }

}
