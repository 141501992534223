/**
 * Service to communicate with MachineSetters resource
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../environment/environment';
import {HttpClient} from '@angular/common/http';
import {Failure} from '../../model/failure';

@Injectable()
export class FailureReportingEditService {
    private static BASE_URL = `${environment.pdFailureReportingApiUrl}`;

    constructor(private http: HttpClient) {
    }

    findAllFailureReporting(): Observable<Failure[]> {
        return this.http.get<Failure[]>(FailureReportingEditService.BASE_URL);
    }

    addFailureReport(failure: Failure) {
        return this.http.post<void>(FailureReportingEditService.BASE_URL, failure);
    }

    findFailureById(id: string) {
        return this.http.get<Failure>(`${FailureReportingEditService.BASE_URL}/${id}`);
    }

    updateFailureReport(failure: Failure) {
        return this.http.put<void>(`${FailureReportingEditService.BASE_URL}/${failure.id}`, failure);
    }
}
