// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const DS_BASE_API_URL = 'https://hanplast.pro-duction.com/api';
// const DS_BASE_API_URL = 'http://localhost:80';

export const environment = {
    // production: false,
    pdOperatorsApiUrl: `${DS_BASE_API_URL}/operators`,
    pdShiftsApiUrl: `${DS_BASE_API_URL}/shifts`,
    pdShiftPlansApiUrl: `${DS_BASE_API_URL}/shift-plans`,
    pdOrdersApiUrl: `${DS_BASE_API_URL}/orders`,
    pdOrdersMachineSapIdApiUrl: `${DS_BASE_API_URL}/orders/machineSapId`,
    pdOrdersRepackagingApiUrl: `${DS_BASE_API_URL}/orders/repackaging`,
    pdOrdersPackingApiUrl: `${DS_BASE_API_URL}/orders/packing`,
    pdOrderExtraOrdersApiUrl: `${DS_BASE_API_URL}/orders/extraOrders`,
    pdOrdersAssemblyAndPaintShopApiUrl: `${DS_BASE_API_URL}/orders/assemblyAndPaintShop`,
    pdMachinesApiUrl: `${DS_BASE_API_URL}/machines`,
    pdDashboardApiUrl: `${DS_BASE_API_URL}/dashboard`,
    pdDashboardLastHoursApiUrl: `${DS_BASE_API_URL}/historical-items`,
    pdMachineSettersApiUrl: `${DS_BASE_API_URL}/machineSetters`,
    pdFailureReportingApiUrl: `${DS_BASE_API_URL}/failureReporting`,
    pdSetterShiftsApiUrl: `${DS_BASE_API_URL}/setter-shifts`,
    pdEmployeesApiUrl: `${DS_BASE_API_URL}/employees`,
    pdLabelsApiUrl: `${DS_BASE_API_URL}/labels`,
    pdMobileApiUrl: `${DS_BASE_API_URL}/mobile`
};
