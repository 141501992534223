/**
 * Service to communicate with MachineSetters resource
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient} from '@angular/common/http';
import {Failure} from '../model/failure';

@Injectable()
export class FailureReportingService {
    private static BASE_URL = `${environment.pdFailureReportingApiUrl}`;

    constructor(private http: HttpClient) {
    }

    findAllFailureReporting(): Observable<Failure[]> {
        return this.http.get<Failure[]>(FailureReportingService.BASE_URL);
    }

    findAllClosedFailureReporting(): Observable<Failure[]> {
        return this.http.get<Failure[]>(FailureReportingService.BASE_URL + '/closed');
    }

    addFailureReport(failure: Failure) {
        return this.http.post<void>(FailureReportingService.BASE_URL, failure);
    }
}
