import { Injectable } from '@angular/core';
import { CookieService } from 'angular2-cookie/core';

const TOKEN_KEY = 'AuthToken';
const USERNAME_KEY = 'AuthUsername';
const AUTHORITIES_KEY = 'AuthAuthorities';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  private roles: Array<string> = [];
  constructor(private cookieService: CookieService) { }

  signOut() {
    this.cookieService.remove(TOKEN_KEY);
    this.cookieService.remove(USERNAME_KEY);
    this.cookieService.remove(AUTHORITIES_KEY);
  }

  public saveToken(token: string) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 7);
    this.cookieService.put(TOKEN_KEY, token, { expires: expireDate });
  }

  public getToken(): string {
    return this.cookieService.get(TOKEN_KEY);
  }

  public saveUsername(username: string) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 7);
    this.cookieService.put(USERNAME_KEY, username, { expires: expireDate });
  }

  public getUsername(): string {
    return this.cookieService.get(USERNAME_KEY);
  }

  public saveAuthorities(authorities: string[]) {
    const expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 7);
    this.cookieService.put(AUTHORITIES_KEY, JSON.stringify(authorities), { expires: expireDate });
  }

  public getAuthorities(): string[] {
    this.roles = [];
    if (this.cookieService.get(TOKEN_KEY)) {
      JSON.parse(this.cookieService.get(AUTHORITIES_KEY)).forEach(authority => {
        this.roles.push(authority.authority);
      });
    }
    return this.roles;
  }
}
