/**
 * Service to communicate with MachineSetters resource
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient} from '@angular/common/http';
import {MachineSetter} from '../model/machineSetter';

@Injectable()
export class MachineSettersService {
    private static BASE_URL = `${environment.pdMachineSettersApiUrl}`;

    constructor(private http: HttpClient) {
    }

    findAllMachineSetters(): Observable<MachineSetter[]> {
        return this.http.get<MachineSetter[]>(MachineSettersService.BASE_URL);
    }

    updateMobilePhone(machineSetter: MachineSetter) {
        return this.http.post<void>(MachineSettersService.BASE_URL, machineSetter);
    }
}
