/**
 * Service to communicate with MachineSetters resource
 */
import {Injectable} from '@angular/core';
import {environment} from '../../environment/environment';
import {HttpClient} from '@angular/common/http';
import {Label} from '../../model/label';

@Injectable()
export class LabelEditService {
    private static BASE_URL = `${environment.pdLabelsApiUrl}`;

    constructor(private http: HttpClient) {
    }

    findLabelById(id: string) {
        return this.http.get<Label>(`${LabelEditService.BASE_URL}/${id}`);
    }

    addLabel(label: Label) {
        return this.http.post<void>(LabelEditService.BASE_URL, label);
    }

    updateLabel(label: any) {
        return this.http.put<void>(`${LabelEditService.BASE_URL}/${label.id}`, label);
    }
}
