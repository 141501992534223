/**
 * Service to communicate with operators resource
 */
import {Injectable} from '@angular/core';
import {Operator} from '../model/operator';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorageService} from '../auth/token-storage.service';
import {OperatorSearchCriteria} from './operator-search-criteria.model';
import {OperatorAndShift} from '../model/operatorAndShift';
import {Order} from '../model/order';
import {Machine} from '../model/machine';

@Injectable()
export class MachinesService {
    private static BASE_URL = `${environment.pdMachinesApiUrl}`;

    constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
    }

    findAllOMachines(): Observable<Machine[]> {
        return this.http.get<Machine[]>(MachinesService.BASE_URL);
    }
}
