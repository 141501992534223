/**
 * Service to communicate with operators resource
 */
import {Injectable} from '@angular/core';
import {Operator} from '../model/operator';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorageService} from '../auth/token-storage.service';
import {OperatorAndShift} from '../model/operatorAndShift';
import {Label} from '../model/label';

@Injectable()
export class LabelsService {
    private static BASE_URL = `${environment.pdLabelsApiUrl}`;

    constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
    }

    findAllLabels(): Observable<Label[]> {
        return this.http.get<Label[]>(LabelsService.BASE_URL);
    }

    // addOperatorsToShift(operators: Operator[]) {
    //     return this.http.post<void>(LabelsService.BASE_URL, operators);
    // }
    //
    // addOperatorsToNextShift(operators: Operator[]) {
    //     return this.http.post<void>(LabelsService.BASE_URL + '/nextShift', operators);
    // }
    //
    // deleteEmployee(id: string): Observable<Number[]> {
    //     return this.http.delete<Number[]>(LabelsService.BASE_URL + `/${id}`);
    // }
    findLabelByDashboardId(dashboardId: number) {
        return this.http.get<Label[]>(LabelsService.BASE_URL + `/byDashboardId/${dashboardId}`);
    }
}
