/**
 * Service to communicate with shifts resource
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient} from '@angular/common/http';
import {Shift} from '../model/shift';
import {SetterAssignment} from '../model/setterAssignment';
import {SetterShift} from '../model/setterShift';

@Injectable()
export class ShiftService {
    private static BASE_URL = `${environment.pdShiftsApiUrl}`;
    private static SETTER_BASE_URL = `${environment.pdSetterShiftsApiUrl}`;

    constructor(private http: HttpClient) {
    }

    findAllOperatorsForCurrentShift(id: string): Observable<Shift[]> {
        return this.http.get<Shift[]>(ShiftService.BASE_URL + `/shift/${id}`);
    }

    deleteOperatorFromCurrentShift(id: number) {
        return this.http.delete(ShiftService.BASE_URL + `/${id}`);
    }

    findOperatorForCurrentShift(id: number): Observable<Shift> {
        return this.http.get<Shift>(ShiftService.BASE_URL + `/${id}`);
    }

    updateOperatorForCurrentShift(id: number, machines: number[]) {
        return this.http.post<void>(ShiftService.BASE_URL + `/assignment/${id}`, machines);
    }

    updateSetterShiftForCurrentShift(setterAssignment: SetterAssignment) {
        return this.http.post<void>(ShiftService.SETTER_BASE_URL, setterAssignment);
    }

    findAllMachineSettersShifts(shiftPlanId: string): Observable<SetterShift[]>  {
        return this.http.get<SetterShift[]>(ShiftService.SETTER_BASE_URL + `/${shiftPlanId}`);
    }

    updateOperatorOrdersForCurrentShift(id: number, orders: number[]) {
        return this.http.post<void>(ShiftService.BASE_URL + `/order/${id}`, orders);
    }

    updateOperatorExtraOrdersForCurrentShift(id: number, orders: number[]) {
        return this.http.post<void>(ShiftService.BASE_URL + `/extraOrder/${id}`, orders);
    }

    updateAssemblyAndPaintMinutesForCurrentShift(id: number, machines: number[]) {
        return this.http.post<void>(ShiftService.BASE_URL + `/assemblyAndPaintMinutes/${id}`, machines);
    }
}
