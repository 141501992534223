/**
 * Service to communicate with operators resource
 */
import {Injectable} from '@angular/core';
import {Operator} from '../model/operator';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorageService} from '../auth/token-storage.service';
import {OperatorAndShift} from '../model/operatorAndShift';

@Injectable()
export class EmployeesService {
    private static BASE_URL = `${environment.pdEmployeesApiUrl}`;

    constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
    }

    findAllOperators(): Observable<OperatorAndShift> {
        return this.http.get<OperatorAndShift>(EmployeesService.BASE_URL);
    }

    addOperatorsToShift(operators: Operator[]) {
        return this.http.post<void>(EmployeesService.BASE_URL, operators);
    }

    addOperatorsToNextShift(operators: Operator[]) {
        return this.http.post<void>(EmployeesService.BASE_URL + '/nextShift', operators);
    }

    deleteEmployee(id: string): Observable<Number[]> {
        return this.http.delete<Number[]>(EmployeesService.BASE_URL + `/${id}`);
    }
}
