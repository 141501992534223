/**
 * Service to communicate with shifts resource
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient} from '@angular/common/http';
import {Shift} from '../model/shift';
import {ShiftPlan} from '../model/shiftPlan';

@Injectable()
export class ShiftPlanService {
    private static BASE_URL = `${environment.pdShiftPlansApiUrl}`;

    constructor(private http: HttpClient) {
    }

    findAllShiftPlans(): Observable<ShiftPlan[]> {
        return this.http.get<Shift[]>(ShiftPlanService.BASE_URL);
    }
}
