/**
 * Service to communicate with operators resource
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {TokenStorageService} from '../auth/token-storage.service';
import {Dashboard} from '../model/dashboard';
import {DashboardDetails} from '../model/dashboardDetails';
import {LastHours} from '../model/lastHours';
import {RemovedOrder} from '../model/removedOrder';
import {SimpleOrder} from '../model/simpleOrder';
import {OverrideCycleTime} from '../model/overrideCycleTime';
import {HalStatus} from '../model/halStatus';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class DashboardService {
    private static BASE_URL = `${environment.pdDashboardApiUrl}`;
    private static BASE_URL_LAST_HOURS = `${environment.pdDashboardLastHoursApiUrl}`;

    constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
    }

    findDashboard(): Observable<Dashboard[]> {
        return this.http.get<Dashboard[]>(DashboardService.BASE_URL);
    }

    assignOrder(dashboardDetails: DashboardDetails) {
        return this.http.patch<Dashboard[]>(DashboardService.BASE_URL, dashboardDetails);
    }

    findMachineLastHoursDetails(id: number): Observable<LastHours[]> {
        return this.http.get<LastHours[]>(DashboardService.BASE_URL_LAST_HOURS + `/${id}`);
    }

    removeAssignedOrder(removedOrder: RemovedOrder) {
        return this.http.patch(DashboardService.BASE_URL + `/removeAssignedOrder`, removedOrder);
    }

    overrideCycleTime(overrideCycleTime: OverrideCycleTime) {
        return this.http.patch(DashboardService.BASE_URL + `/overrideCycleTime`, overrideCycleTime);
    }

    updateSelectedLabel(dashboardId: number, labelId: number) {
        return this.http.patch(DashboardService.BASE_URL + `/updateSelectedLabelId/${dashboardId}`, labelId);
    }

    updateHal(dashboardId: number, status: HalStatus) {
        return this.http.post(DashboardService.BASE_URL + `/updateHalStatus/${dashboardId}`, status);
    }

    findDashboardById(id: string) {
        return this.http.get<Dashboard>(DashboardService.BASE_URL + `/${id}`);
    }
}
