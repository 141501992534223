/**
 * Service to communicate with operators resource
 */
import {Injectable} from '@angular/core';
import {Operator} from '../model/operator';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenStorageService} from '../auth/token-storage.service';
import {OperatorSearchCriteria} from './operator-search-criteria.model';
import {OperatorAndShift} from '../model/operatorAndShift';

@Injectable()
export class OperatorsService {
    private static BASE_URL = `${environment.pdOperatorsApiUrl}`;

    constructor(private http: HttpClient, private tokenStorageService: TokenStorageService) {
    }

    findAllOperators(operatorSearchCriteria: OperatorSearchCriteria): Observable<OperatorAndShift> {
        let params = new HttpParams();
        const operatorIds = operatorSearchCriteria.operatorId;
        for (const operatorId of operatorIds) {
            params = params.append('roleIds', operatorId.toString());
        }
        params = params.append('extendHours', String(operatorSearchCriteria.extendHours));
        const options = {params: params};
        return this.http.get<OperatorAndShift>(OperatorsService.BASE_URL, options);
    }

    addOperatorsToShift(operators: Operator[]) {
        return this.http.post<void>(OperatorsService.BASE_URL, operators);
    }

    addOperatorsToNextShift(operators: Operator[]) {
        return this.http.post<void>(OperatorsService.BASE_URL + '/nextShift', operators);
    }
}
