/**
 * Service to communicate with shifts resource
 */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../environment/environment';
import {HttpClient} from '@angular/common/http';
import {Shift} from '../model/shift';
import {SetterAssignment} from '../model/setterAssignment';
import {SetterShift} from '../model/setterShift';
import {MobileDetails} from '../model/mobileDetails';

@Injectable()
export class MobileService {
    private static BASE_URL = `${environment.pdMobileApiUrl}`;

    constructor(private http: HttpClient) {
    }

    addFaultItem(dashboardId: number, details: MobileDetails): Observable<number> {
        return this.http.post<number>(`${MobileService.BASE_URL}/addFaultItem/${dashboardId}`, details);
    }

    addWeight(dashboardId: number, details: MobileDetails) {
        return this.http.post<void>(`${MobileService.BASE_URL}/addWeight/${dashboardId}`, details);
    }

}
